import React, { useRef } from 'react';
import styles from './FinalEpq.module.scss';
import AddressForm from '~/components/shared/AddressFormBrand';
import Typography from '~/components/shared/Typography';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import whiteGif from '@public/images/satellite-streak.gif';
import blackGif from '@public/images/satellite-streak-black.gif';
import whiteImage from '@public/images/satellite-streak-animation.png';
import blackImage from '@public/images/satellite-streak-animation-black.png';
import { useMonarchPreampPlacementContext } from '@redventures/cohesion-utils-react';

const satellites = {
  white: {
    image: whiteImage,
    gif: whiteGif,
  },
  black: {
    image: blackImage,
    gif: blackGif,
  },
  none: {
    image: null,
    gif: null,
  },
};

const BASE_DATA = {
  addressCTACopy: 'Shop plans',
  addressLabelColor: 'white',
  addressLabelCopy: 'Enter your address to check available plans',
  addressPlaceHolderCopy: 'Enter your address',
  backgroundImage: 'final-epq-banner-hills',
  gifSelection: 'none',
  headline: 'Find out what NEW Hughesnet plan could be right for you',
  headlineTextColor: 'white',
  mapPinIconColor: '#154B8E',
  subhead:
    'Our plans service across the nation so you can stay connected from wherever you live.',
  subheadTextColor: 'white',
};

export default function FinalEpq() {
  const { data: monarchData } = useMonarchPreampPlacementContext();
  const data = { ...BASE_DATA, ...monarchData };
  const {
    headlineTextColor,
    subheadTextColor,
    headline,
    subhead,
    backgroundImage,
    gifSelection,
  } = data;

  const { image: gifImage, gif } = satellites[gifSelection];

  //Checks if animation is disabled in the OS
  const reducedMotion = window.matchMedia(
    '(prefers-reduced-motion: reduce)'
  ).matches;

  //Refs used for eventing & animation
  const finalEpqSection = useRef();
  const wrapperRef = useRef();
  const mozilla = navigator.userAgent.includes('Mozilla');

  useIntersectionObserver(
    [finalEpqSection],
    () =>
      fireElementViewedEvent(
        'final epq rebrand',
        'final epq rebrand section',
        'final epq rebrand',
        headline
      ),
    undefined,
    { threshold: 0.75 }
  );

  useIntersectionObserver(
    [wrapperRef],
    () => {
      if (!wrapperRef || !wrapperRef.current) {
        return;
      }

      if (!reducedMotion && gif) {
        const gifImg = new Image();
        gifImg.src = gif;
        gifImg.width = 207;
        gifImg.height = 90;
        gifImg.style.objectFit = 'cover';
        if (wrapperRef.current.firstChild) {
          wrapperRef.current.firstChild.replaceWith(gifImg);
        } else {
          wrapperRef.current?.appendChild(gifImg);
        }
      }
    },
    undefined,
    { threshold: 1.0 }
  );

  const bgImage = backgroundImage
    ? `/images/section/${backgroundImage}.jpg`
    : `/images/section/final-epq-banner.jpg`;

  const isPositionRight = backgroundImage == 'final-epq-banner-hills';

  return (
    <div
      ref={finalEpqSection}
      className={`${styles.container} ${mozilla && styles.mozilla}`}
    >
      <img
        className={`${styles.backgroundImage} ${
          isPositionRight
            ? styles.backgroundPositionRight
            : styles.backgroundPositionCenter
        }`}
        src={bgImage}
        alt=''
      />
      <div className={('grid-container', styles.content)}>
        <div className={styles.headers}>
          <div ref={wrapperRef} className={styles.logoWrapper}>
            {reducedMotion && gifImage && (
              <img
                src={gifImage}
                width={207}
                height={90}
                style={{ objectFit: 'cover' }}
                alt='satellite animation'
              />
            )}
          </div>
          <Typography variant='h1' className={styles.heading}>
            <span
              dangerouslySetInnerHTML={{ __html: headline }}
              style={{ color: headlineTextColor }}
            />
          </Typography>
          <Typography variant='h4' className={styles.subheading}>
            <span
              dangerouslySetInnerHTML={{ __html: subhead }}
              style={{ color: subheadTextColor }}
            />
          </Typography>
        </div>
        <div className={styles.addressContainer}>
          <AddressForm metadata={data} isFinalEpq={true} />
        </div>
      </div>
    </div>
  );
}
